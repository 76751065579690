import React from 'react'
import Layout from '../../containers/Layout'
import { Listing } from 'web-components'
import { imageCDNUrl } from '../../helpers'

import products from '../../config/text/products.json'

const hotChocolate = products.hotChocolate

class HotChocolate extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/hot-chocolate.jpg' heroText={hotChocolate.title} title={hotChocolate.meta.title}>
        <div className='hotChocolate container'>
          <div className='row'>
            <div className='col-xs-12'>
              <p>{hotChocolate.description}</p>
            </div>
          </div>
          {hotChocolate.products.map(product => {
            return (
              <Listing
                key={product.name}
                description={product.description}
                footnote={product.footnote}
                img={{
                  ...product.img,
                  src: imageCDNUrl(product.img.src)
                }}
                name={product.name}
                {...{ points: product.points }}
              />
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default HotChocolate
